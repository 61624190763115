<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" sm="8" md="6" lg="5" xl="4" class="order-2 order-sm-1">
          <wx-text-field
            v-model="search"
            :label="$t('common.search')"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            clearable
          />
          <div class="mt-6 wx-typo-sm">
            <span class="ml-1 font-weight-light">{{ $t("alerts.puFilterOptionTitle") }}</span>
            <wx-autocomplete
              v-model="productionUnitId"
              :items="availableProductionUnitSelection"
              :placeholder="$t('product.anyPU')"
              item-value="value"
              item-text="text"
              class="mt-2 mb-1"
              hide-details="true"
              validate-on-blur
              clearable
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="6"
          lg="7"
          xl="8"
          class="action-buttons order-1 order-sm-2 d-flex flex-wrap justify-center justify-sm-end align-sm-end"
        >
          <wx-btn-standard class="mb-2 mb-sm-1" @click="addNewAlert">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("alerts.alert") }}
          </wx-btn-standard>
        </v-col>
      </v-row>
    </header>

    <wx-data-table
      class="wx-panel mt-5"
      :items="transformedAlerts"
      :headers="tableHeaders"
      :search="search"
      @click:row="redirectToAlert"
      mobile-breakpoint="sm"
      :footer-props="{
        itemsPerPageText: this.$t('alerts.listing.itemsPerPage'),
        itemsPerPageAllText: this.$t('common.listing.showAllOption'),
      }"
    >
      <template #[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} {{ $t("common.listing.itemOfItems") }} {{ items.itemsLength }}
      </template>
      <template v-slot:[`item.production_units`]="{ item }">
        <div v-if="isAllProductionUnitSelected(item.production_units)">
          <span class="wx-typo-sm ml-3">{{ $t("common.all") }}</span>
        </div>
        <div v-else>
          <v-chip v-for="(puName, index) in item.production_units" :key="`pu-${index}`" small outlined class="mr-1">
            {{ puName }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <v-btn class="disabled-btn" icon large>
          <v-avatar color="primary" size="30">
            {{ getUserInitials(item.user_id) }}
          </v-avatar>
        </v-btn>
      </template>
      <template v-slot:[`item.enabled`]="{ item }">
        <v-chip :color="getChipColor(item)" small>{{ getChipLabel(item) }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <wx-btn-icon :aria-label="$t('common.edit')" class="my-sm-2 mr-4" small :to="toAlert(item)">
          <v-icon small>mdi-pencil</v-icon>
        </wx-btn-icon>
        <!--
          Delete btn
          -->
        <dialog-confirm-deletion
          :deletion-title="$t('alerts.deletion.title')"
          :info-box-text-title="$t('common.attention')"
          :info-box-text="$t('alerts.deletion.warningMessage')"
          :confirm-text-prefix="$t('alerts.deletion.confirmMessagePrefix')"
          :element-display-name="getAlertName(item)"
          :delete-button-text="$t('alerts.deletion.deleteAlertBtn')"
          :small-activator-btn="true"
          @confirm="deleteAlert(item)"
          activator-btn-class="my-sm-2 mx-0"
        />
      </template>
    </wx-data-table>
  </fragment>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete.vue";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxTextField from "@/components/ui/WxTextField.vue";
import WxBtnIcon from "@/components/ui/WxBtnIcon.vue";
import { mapActions, mapGetters } from "vuex";
import WxDataTable from "@/components/ui/WxDataTable.vue";
import AlertService from "@/components/alerts/AlertService";
import DialogConfirmDeletion from "@/components/DialogConfirmDeletion.vue";
import RouteService from "@/router/RouteService";

export default {
  name: "AlertsList",
  components: {
    WxDataTable,
    WxAutocomplete,
    WxBtnStandard,
    WxTextField,
    WxBtnIcon,
    DialogConfirmDeletion,
  },
  data() {
    return {
      search: null,
      productionUnitId: null,
      alerts: [],
    };
  },
  watch: {
    activeFactory() {
      this.fetchAlerts();
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits", "factoryUsers"]),
    transformedAlerts() {
      return this.filteredAlertsByPu.map((a) => {
        let formattedAlert = { ...a };
        formattedAlert.name = this.getAlertName(a);
        formattedAlert.production_units = a.production_unit_ids.map((puId) => this.getProductionUnitName(puId));
        return formattedAlert;
      });
    },
    filteredAlertsByPu() {
      if (!this.productionUnitId) return this.alerts;
      return this.alerts.filter((a) => a.production_unit_ids.includes(this.productionUnitId));
    },
    availableProductionUnitSelection() {
      let options = [];
      if (this.activeFactoryProductionUnits) {
        let puList = this.activeFactoryProductionUnits
          .map((pu) => ({
            value: pu.id,
            text: pu.name,
          }))
          .sort((p1, p2) => (p1.name <= p2.name ? 1 : -1));
        options.push(...puList);
      }
      return options;
    },
    tableHeaders() {
      return [
        {
          text: this.$t("alerts.headers.trigger"),
          value: "name",
          class: "col-name",
        },
        {
          text: this.$t("productionUnit.title"),
          value: "production_units",
          class: "col-pu",
          sortable: false,
        },
        {
          text: this.$t("alerts.headers.owner"),
          value: "user",
          class: "col-owner",
          sortable: false,
        },
        {
          text: this.$t("user.status"),
          value: "enabled",
          class: "col-enabled",
        },
        {
          text: this.$t("common.actions"),
          value: "actions",
          class: "col-actions two-btn-wide",
          sortable: false,
          align: "right",
        },
      ];
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "handleHttpError"]),
    addNewAlert() {
      this.$router.push(RouteService.toCreateAlert());
    },
    redirectToAlert(alert) {
      this.$router.push(RouteService.toAlert(alert.id));
    },
    toAlert(alert) {
      return RouteService.toAlert(alert.id);
    },
    fetchAlerts() {
      if (!this.activeFactory) return;
      AlertService.getAlerts(this.activeFactory.id)
        .then((httpResponse) => (this.alerts = httpResponse.data))
        .catch((httpError) => console.log(httpError));
    },
    getChipColor(item) {
      return item.enabled ? "primary" : "secondary";
    },
    getChipLabel(item) {
      return item.enabled ? this.$t("user.statusActive") : this.$t("user.statusInactive");
    },
    getUserInitials(userId) {
      const displayName = this.factoryUsers.find((u) => u.id === userId)?.display_name;
      if (!displayName) return "N/A";
      const names = displayName.split(" ");
      if (names.length === 1) return names[0].charAt(0).toUpperCase();
      return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
    },
    deleteAlert(item) {
      AlertService.deleteAlert(item.factory_id, item.id)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertDeletionMessage"));
          this.fetchAlerts();
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
        });
    },
    getAlertName(item) {
      switch (item.type) {
        case "UnplannedCurrentDowntime":
          return `${this.$t("alerts.types.currentDowntime")} - ${
            item.unplanned_current_downtime_rules.duration_minutes
          } ${this.$t("common.minutes")}`;
        default:
          return "Default Alert Trigger";
      }
    },
    getProductionUnitName(puId) {
      return this.activeFactoryProductionUnits?.find((pu) => pu.id === puId)?.name;
    },
    isAllProductionUnitSelected(puIds) {
      return puIds.length === this.activeFactoryProductionUnits?.length;
    },
  },
  mounted() {
    this.fetchAlerts();
  },
};
</script>

<style lang="scss" scoped>
.disabled-btn {
  pointer-events: none;
}

::v-deep .col-actions {
  width: 15%;
  text-align: right !important;
}

::v-deep .col-enabled {
  width: 10%;
}

::v-deep .col-owner {
  width: 10%;
}
</style>
